import { useEffect, useState } from "react"
import { Layout, SegmentedFilter } from "../components"
import { getCoupons } from "../helpers/getTokenAccounts"
import AccountSidebar from "./AccountSidebar"
import styles from "./RewardPage.module.scss"
import { useSelector } from "react-redux"
import { formatCents } from "../helpers/money"
import { Unavailable } from "../css/icons"
import { recordIterablePageview } from "../helpers/recordIterableUser"

const RewardPage = () => {
  const user = useSelector((state) => state.user.user)
  const [rewards, setRewards] = useState([])
  const [selected, setSelected] = useState("Active")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadRewards()
    if (user) {
      recordIterablePageview(user, window.location.pathname, "Reward Page")
    }
  }, [user?.publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadRewards = async () => {
    const rewards = await getCoupons(user)
    setRewards(rewards)
    setLoading(false)
  }

  return (
    <Layout contentClassName={styles.container}>
      <div className={styles.rewardInner}>
        <AccountSidebar />
        <div className={styles.rewardContainer}>
          <h1>My Rewards</h1>
          <SegmentedFilter
            options={["Active", "History"]}
            fullWidth={true}
            onSelect={(value) => setSelected(value)}
            selected={selected}
          />
          <div className={styles.rewardList}>
            {rewards &&
              rewards
                .filter((reward) =>
                  selected === "Active"
                    ? reward.metadata.json.state === "unused"
                    : reward.metadata.json.state !== "unused"
                )
                .map((reward) => (
                  <div className={styles.rewardCard}>
                    <img src={reward.metadata.json.image} alt="reward" />
                    <div className={styles.rewardCardBody}>
                      <h3>{reward.metadata.json.name}</h3>
                      <p>
                        {reward.metadata.json.discount_type === "percent" && (
                          <>
                            {reward.metadata.json.discount * 100}% Off Order -{" "}
                          </>
                        )}
                        {reward.metadata.json.discount_type === "flat" && (
                          <>
                            {formatCents(reward.metadata.json.discount)} Off
                            Order -{" "}
                          </>
                        )}
                        {reward.metadata.json.minimum && (
                          <>
                            {formatCents(reward.metadata.json.minimum)} minimum
                            spend -{" "}
                          </>
                        )}
                        {reward.metadata.json.max_discount && (
                          <>
                            {formatCents(reward.metadata.json.max_discount)} max
                            discount
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                ))}
            {rewards?.length === 0 && !loading && (
              <div className={styles.noRewards}>
                <Unavailable />
                <p>No rewards yet!</p>
              </div>
            )}
            {loading && (
              <div className={styles.noRewards}>
                <img
                  src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
                  className={styles.loading}
                  alt="loading"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RewardPage
