import { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Blurhash } from "react-blurhash"
import classNames from "classnames"
import {
  Button,
  CustomDatePicker,
  EventCarousel,
  InlineInput,
  Layout,
} from "../components"
import styles from "./ArtistPage.module.scss"
import { useParams } from "react-router-dom"
import EventListItem from "./EventListItem"
import CompactEventCard from "./CompactEventCard"
import {
  AngleDown,
  AppleMusic,
  ArrowDown,
  Calendar,
  GoldStar,
  LocationArrow,
  RedX,
  Spotify,
  XCircle,
} from "../css/icons"
import { useDetectClickOutside } from "../hooks/detectClickOutside"
import QualityXperienceGuarantee from "../Home/QualityXperienceGuarantee"
import { useSelector } from "react-redux"
import { recordIterablePageview } from "../helpers/recordIterableUser"

const ArtistPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const locationMenuRef = useRef(null)
  const homeAwayMenuRef = useRef(null)

  const isMidfunnel = window.location.pathname.includes("details")

  useDetectClickOutside(locationMenuRef, () => {
    setShowLocationMenu(false)
  })

  useDetectClickOutside(homeAwayMenuRef, () => {
    setShowHomeAwayMenu(false)
  })

  const [performer, setPerformer] = useState(null)
  const [nearbyName, setNearbyName] = useState(null)
  const [events, setEvents] = useState([])
  const [nearbyEvents, setNearbyEvents] = useState([])
  const [filteredRecommendations, setFilteredRecommendations] = useState([])
  const [numberOfResults, setNumberOfResults] = useState(8)
  const [numberOfNearbyResults, setNumberOfNearbyResults] = useState(2)
  const [numHomeResults, setNumHomeResults] = useState(5)
  const [numAwayResults, setNumAwayResults] = useState(5)
  const [selectedSportsFilter, setSelectedSportsFilter] = useState("All")
  const [eventsLoaded, setEventsLoaded] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [showDateMenu, setShowDateMenu] = useState(false)
  const [locations, setLocations] = useState([])
  const [location, setLocation] = useState(null)
  const [showLocationMenu, setShowLocationMenu] = useState(false)
  const [showAllHomeGames, setShowAllHomeGames] = useState(false)
  const [selectedTab, setSelectedTab] = useState("events")
  const [showHomeAwayMenu, setShowHomeAwayMenu] = useState(false)
  const [nonFilteredHomeGames, setNonFilteredHomeGames] = useState(null)
  const [submittedEmail, setSubmittedEmail] = useState(false)
  const user = useSelector((state) => state.user.user)

  const performer_id = params.artist_id

  // window.performerTracked = false

  useEffect(() => {
    if (!performer) {
      getPerformer()
    }
    getAllEvents()
    getNearbyEvents()
  }, [performer]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      if (
        (user?.email || user?.uuid) &&
        performer &&
        events[0] &&
        !window.PERFORMER_TRACKED
      ) {
        recordIterablePageview(
          user,
          window.location.pathname,
          performer?.name,
          {
            artistId: performer?.performer_id,
            artistName: performer?.name,
            eventType: events[0]?.event_type,
            category: events[0]?.category,
            eventSubType: events[0]?.genre || events[0]?.league,
          }
        )
        window.PERFORMER_TRACKED = true
      }
    }, 1000)

    return () => {
      window.PERFORMER_TRACKED = false
    }
  }, [performer, events])

  useEffect(() => {
    getPerformer()
    getAllEvents()
    getNearbyEvents()
    setNonFilteredHomeGames(null)
  }, [params.artist_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (events?.length) {
      getLocations()
      getRecommendations({ artistId: performer_id })
    }
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (events[0]?.event_type.indexOf("sport") > -1) {
      filterSports()
    }
  }, [events, performer_id]) // eslint-disable-line react-hooks/exhaustive-deps

  // when the dates or locations change, update the filtered events
  useEffect(() => {
    getAllEvents()
    getNearbyEvents()
  }, [startDate, endDate, location]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPerformer = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/performer-by-id`
    let params = { performer_id: performer_id }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      setPerformer(resp.performer)
    } catch (err) {
      console.log("Error getting performer", err)
    }
  }

  const notNearbyEvents = () => {
    if (!events) return []
    // the all view
    if (nearbyEvents && !location) {
      let nearbyEventIds = []
      for (let i = 0; i < nearbyEvents.length; i++) {
        nearbyEventIds.push(nearbyEvents[i].event_id)
      }
      let notNearby = []
      for (let i = 0; i < events.length; i++) {
        if (nearbyEventIds.indexOf(events[i].event_id) === -1) {
          notNearby.push(events[i])
        }
      }
      return notNearby
    } else if (location) {
      // filter by location
      let events_ = []
      for (let i = 0; i < events.length; i++) {
        if (events[i].venue_city === location.city) {
          events_.push(events[i])
        }
      }
      return events_
    } else {
      return events
    }
  }

  const getAllEvents = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = {
      performer_id: performer_id,
      min_date: startDate,
      max_date: endDate,
    }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setEvents(resp.events)
    setEventsLoaded(true)
  }

  const getNearbyEvents = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = {
      performer_id: performer_id,
      nearby: true,
      min_date: startDate,
      max_date: endDate,
    }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setNearbyEvents(resp.events)
    setNearbyName(resp.nearby)
  }

  const getRecommendations = async ({ artistId }) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = {
      event_type: events[0]?.event_type,
      nearby: true,
    }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()

    let recommendations = resp?.events.filter(
      (event) => event.performer_id !== artistId
    )
    setFilteredRecommendations(recommendations)
  }

  const getLocations = () => {
    const locations = []
    let citiesSeen = []
    events.forEach((event) => {
      if (event.venue_city && citiesSeen.indexOf(event.venue_city) === -1) {
        citiesSeen.push(event.venue_city)
        locations.push({
          city: event.venue_city,
          state: event.venue_state,
        })
      }
    })

    locations.sort((a, b) => {
      if (a.city < b.city) return -1
      if (a.city > b.city) return 1
      return 0
    })

    setLocations(locations)
  }

  const [homeGames, setHomeGames] = useState([])
  const [awayGames, setAwayGames] = useState([])
  const [parking, setParking] = useState([])
  const [allGames, setAllGames] = useState([]) // home and away, not parking

  const filterSports = () => {
    let homeGames = []
    let awayGames = []
    let parking = []
    let all = []

    let eventsToFilter = []

    if (location) {
      eventsToFilter.push(
        ...events.filter((event) => {
          return event.venue_cbsa?.includes(location.city)
        })
      )
    } else {
      eventsToFilter.push(...events)
    }

    eventsToFilter?.forEach((event) => {
      if (event.min_ticket_price === null) return
      // get them all
      if (!event.title.includes("PARKING")) {
        all.push(event)
      }
      // also filter them
      if (event.title.includes("PARKING")) {
        parking.push(event)
      } else if (
        event.performers.find(
          (performer) => performer.performer_id === performer_id
        )?.role === "headliner"
      ) {
        homeGames.push(event)
      } else if (
        event.performers.find(
          (performer) => performer.performer_id === performer_id
        )?.role === "supporting act"
      ) {
        awayGames.push(event)
      }
    })

    setAllGames(all)

    setHomeGames(homeGames)
    if (!nonFilteredHomeGames) {
      setNonFilteredHomeGames(homeGames)
    }
    setAwayGames(awayGames)
    setParking(parking)
    setTimeout(() => {
      setEventsLoaded(true)
    }, 1000)
  }

  const metaTitle = `${performer?.name} | XP — Access your favorite shows`
  const metaDescription = `Get tickets to ${performer?.name} and to your other favorite concerts, sports games, & more, with no hidden fees.`
  const shareFallbackImage =
    "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Social+Share+Image.png"

  const showEndDate =
    startDate &&
    endDate &&
    startDate?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }) !==
      endDate?.toLocaleDateString("en-us", {
        month: "short",
        day: "numeric",
      })

  const formatLocationName = (l) => {
    if (!l) return null
    return `${l.city}, ${l.state}`
  }

  const waitForVideo = (videoId) => {
    return new Promise((resolve) => {
      let video = document.getElementById(videoId)
      if (video) {
        video.oncanplay = () => {
          return resolve(document.getElementById(videoId))
        }
      }
    })
  }

  waitForVideo("bg-shield-artist").then((video) => {
    const fallbackImage = document.getElementById("bg-shield-fallback")
    video?.play().catch((error) => {
      console.error("Error attempting to play", error)
      video.style.display = "none"
      if (fallbackImage) {
        fallbackImage.style.display = "block"
      }
    })
  })

  waitForVideo("bg-bar").then((video) => {
    const fallbackImage = document.getElementById("bg-bar-fallback")
    video?.play().catch((error) => {
      console.error("Error attempting to play", error)
      video.style.display = "none"
      if (fallbackImage) {
        fallbackImage.style.display = "block"
      }
    })
  })

  const subscribe = async (email) => {
    const validEmail = /^\S+@\S+\.\S+$/.test(email)
    if (validEmail) {
      await fetch("https://formspree.io/f/myyqyvpg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      })

      setSubmittedEmail(true)
    }
  }

  const MidfunnelCopy = () => (
    <div className={styles.midfunnel}>
      <div className={styles.midfunnelHeader}>
        <h2>Quality Xperience Guarantee: </h2>
        <GoldStar />
      </div>

      <div className={styles.midfunnelRow}>
        <img
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/pinkstar.png"
          alt="star"
        />
        <p>Tickets are verified and trusted for authenticity</p>
      </div>
      <div className={styles.midfunnelRow}>
        <img
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/pinkenvelope.png"
          alt="envelope"
        />
        <p>Tickets received before your event</p>
      </div>
      <div className={styles.midfunnelRow}>
        <img
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/pinkshield.png"
          alt="shield"
        />
        <p>Secure transactions for payments</p>
      </div>
      <p>
        If anything goes wrong,{" "}
        <a href="https://xpsupport.zendesk.com/hc/en-us/requests/new">
          contact us
        </a>{" "}
        and we'll make it right.
      </p>
    </div>
  )

  const FilterRow = () => (
    <div className={styles.filterContainer}>
      <div className={styles.locationPickerContainer}>
        <Button
          compact
          variant="gray"
          leftIcon={<LocationArrow className={styles.locationArrow} />}
          rightIcon={
            location ? (
              <XCircle className={styles.close} />
            ) : (
              <AngleDown className={styles.angleDown} />
            )
          }
          className={styles.filterButton}
          rightIconOnClick={(e) => {
            e.stopPropagation()
            if (location) {
              setShowLocationMenu(false)
              setLocation(null)
            } else {
              setShowLocationMenu(true)
            }
          }}
          onClick={() => {
            setShowLocationMenu(!showLocationMenu)
          }}
        >
          {formatLocationName(location) || "All Cities"}
        </Button>
        {showLocationMenu && (
          <div className={styles.locationMenuContainer} ref={locationMenuRef}>
            <div
              className={styles.locationItem}
              onClick={() => {
                setLocation(null)
                setShowLocationMenu(false)
              }}
            >
              All Cities
            </div>
            {locations?.map((location) => (
              <div
                className={styles.locationItem}
                onClick={() => {
                  setLocation(location)
                  setShowLocationMenu(false)
                }}
              >
                {formatLocationName(location)}
              </div>
            ))}
          </div>
        )}
      </div>
      <Button
        leftIcon={<Calendar className={styles.calendar} />}
        compact
        variant="gray"
        rightIcon={
          startDate ? (
            <XCircle className={styles.close} />
          ) : (
            <AngleDown className={styles.angleDown} />
          )
        }
        rightIconOnClick={(e) => {
          e.stopPropagation()
          if (startDate) {
            setShowDateMenu(false)
            setStartDate(null)
            setEndDate(null)
          } else {
            setShowDateMenu(!showDateMenu)
          }
        }}
        className={classNames(
          styles.filterButton,
          !!startDate ? styles.selected : styles.unselected
        )}
        onClick={() => {
          setShowDateMenu(!showDateMenu)
        }}
      >
        {!startDate && "All Dates"}
        {startDate &&
          startDate.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          })}
        {showEndDate &&
          ` - ${endDate?.toLocaleDateString("en-us", {
            month: "short",
            day: "numeric",
          })}`}
      </Button>
      {showDateMenu && (
        <CustomDatePicker
          className={styles.datePicker}
          containerStartDate={startDate}
          setDates={(start, end) => {
            setStartDate(start)
            setEndDate(end)
          }}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          containerEndDate={endDate}
          onClose={() => setShowDateMenu(false)}
        />
      )}
      {events[0] &&
        events[0].event_type === "sport" &&
        performer?.home_venue_id && (
          <div
            className={classNames(
              styles.locationPickerContainer,
              styles.homeAwayPickerContainer
            )}
          >
            <Button
              compact
              variant="gray"
              rightIcon={
                location ? (
                  <XCircle className={styles.close} />
                ) : (
                  <AngleDown className={styles.angleDown} />
                )
              }
              className={styles.filterButton}
              rightIconOnClick={(e) => {
                e.stopPropagation()

                setShowHomeAwayMenu(!showHomeAwayMenu)
                setSelectedSportsFilter("All")
              }}
              onClick={() => {
                setShowHomeAwayMenu(!showHomeAwayMenu)
              }}
            >
              {selectedSportsFilter === "All"
                ? "All Games"
                : selectedSportsFilter === "Home"
                ? "Home Games"
                : "Away Games"}
            </Button>
            {showHomeAwayMenu && (
              <div
                className={classNames(styles.locationMenuContainer)}
                ref={homeAwayMenuRef}
              >
                <div
                  className={styles.locationItem}
                  onClick={() => {
                    setSelectedSportsFilter("All")
                    setShowHomeAwayMenu(false)
                  }}
                >
                  All Games
                </div>
                <div
                  className={styles.locationItem}
                  onClick={() => {
                    setSelectedSportsFilter("Home")
                    setShowHomeAwayMenu(false)
                  }}
                >
                  Home Games
                </div>
                <div
                  className={styles.locationItem}
                  onClick={() => {
                    setSelectedSportsFilter("Away")
                    setShowHomeAwayMenu(false)
                  }}
                >
                  Away Games
                </div>
              </div>
            )}
          </div>
        )}
    </div>
  )
  return (
    <>
      <Layout>
        <Helmet>
          <title>{metaTitle}</title>
          <meta property="og:title" content={metaTitle} />
          <meta property="twitter:title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta property="og:description" content={metaDescription} />
          <meta property="twitter:description" content={metaDescription} />
          <meta
            property="og:image"
            content={performer?.default_image || shareFallbackImage}
          />
          <meta
            property="twitter:image"
            content={performer?.default_image || shareFallbackImage}
          />
        </Helmet>
        <div className={styles.blurhashContainer}>
          {performer?.default_img_blurhash && (
            <Blurhash
              hash={performer?.default_img_blurhash}
              width={window.innerWidth}
              height={window.innerHeight}
              resolutionX={32}
              resolutionY={32}
              punch={0}
            />
          )}
          <div className={styles.blurhashOverlay} />
        </div>

        <div className={styles.artistPageContainer}>
          <div className={styles.artistLeftColumn}>
            <div className={styles.breadcrumbs}>
              <Link
                to={`/events?eventType=${
                  events[0]?.event_type === "concert"
                    ? "concerts"
                    : events[0]?.event_type === "sport"
                    ? "sports"
                    : events[0]?.eventType
                }`}
              >
                {events[0]?.event_type === "concert"
                  ? "Concerts"
                  : events[0]?.event_type === "sport"
                  ? "Sports"
                  : events[0]?.eventType}
              </Link>{" "}
              <span>/</span>{" "}
              {events[0]?.event_type === "sport" && events[0]?.category && (
                <>
                  <Link to={`/events?league=${events[0]?.category}`}>
                    {events[0]?.category}
                  </Link>{" "}
                  <span>/</span>{" "}
                </>
              )}
              {events[0]?.event_type === "concert" && events[0]?.genre && (
                <>
                  <Link to={`/events?genre=${events[0]?.genre}`}>
                    {events[0]?.genre}
                  </Link>{" "}
                  <span>/</span>{" "}
                </>
              )}
              {performer?.name}
            </div>
            <div className={styles.artistImageContainer}>
              <picture className={styles.artistImage}>
                <source
                  srcset={performer?.default_img_avif}
                  type="image/avif"
                />
                <source
                  srcset={performer?.default_img_webp}
                  type="image/webp"
                />
                <img
                  src={
                    performer?.default_img ||
                    "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
                  }
                  alt={performer?.name}
                  className={styles.artistImage}
                />
              </picture>
            </div>
            <div className={styles.performerNameContainer}>
              <h1 className={styles.performerName}>
                {performer?.name}
                {isMidfunnel && <> Tickets</>}
              </h1>
              {/* {isMidfunnel && <GoldStar />} */}
            </div>

            {isMidfunnel ? (
              <>{window.innerWidth > 960 && <MidfunnelCopy />}</>
            ) : (
              <>
                {(performer?.spotify_id || performer?.apple_id) && (
                  <>
                    <div className={styles.streamingHeader}>
                      Streaming Links
                    </div>

                    <div className={styles.streamingLinks}>
                      <div className={styles.linkContainer}>
                        {performer?.spotify_id && (
                          <a
                            href={`https://open.spotify.com/artist/${performer?.spotify_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>Spotify</span>
                            <Spotify />
                          </a>
                        )}
                      </div>
                      <div className={styles.linkContainer}>
                        {performer?.apple_id && (
                          <a
                            href={`https://music.apple.com/us/artist/${performer?.apple_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>Apple Music</span>
                            <AppleMusic />
                          </a>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className={styles.artistEventList}>
            {events[0] && events[0].event_type !== "sport" && (
              <>
                {!location && nearbyName && nearbyEvents.length > 0 && (
                  <div className={styles.nearbyEventContainer}>
                    <div className={styles.nearbyEvents}>
                      <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        preload="auto"
                        className={styles.nearbyVideo}
                      >
                        <source
                          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/ticket_video_artist.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <h2>Events Near Me</h2>
                      {numberOfNearbyResults &&
                        nearbyEvents.length > numberOfNearbyResults + 1 && (
                          <div
                            className={styles.moreButton}
                            onClick={() => setNumberOfNearbyResults(null)}
                          >
                            More
                          </div>
                        )}
                    </div>
                    {nearbyEvents.map((event, i) => {
                      if (numberOfNearbyResults && i > numberOfNearbyResults)
                        return null
                      return (
                        <EventListItem event={event} performer={performer} />
                      )
                    })}
                  </div>
                )}

                {notNearbyEvents()?.length > 0 && (
                  <div>
                    {location ? (
                      <h2>{formatLocationName(location)}</h2>
                    ) : (
                      <h2>All {performer?.name} Events</h2>
                    )}
                    <FilterRow />
                    {notNearbyEvents()?.map((event, i) => {
                      if (numberOfResults && i >= numberOfResults) return null
                      return (
                        <EventListItem event={event} performer={performer} />
                      )
                    })}
                  </div>
                )}
                {numberOfResults && events?.length > numberOfResults && (
                  <Button
                    compact
                    rightIcon={<ArrowDown />}
                    className={styles.showMoreButton}
                    onClick={() => setNumberOfResults(null)}
                    variant="outline"
                    rm
                  >
                    Show More
                  </Button>
                )}
              </>
            )}
            {eventsLoaded && events?.length === 0 && (
              <div className={styles.noResults}>
                <RedX />
                <h1>No Events Found</h1>
                <p>Try another search or adjust your filters.</p>
                <Button
                  onClick={() => navigate("/events")}
                  variant="beigeOutline"
                  className={styles.explore}
                >
                  Back
                </Button>
              </div>
            )}

            {events[0] && events[0].event_type === "sport" && (
              <>
                {/* <div className={styles.tabContainer}>
                  <div
                    className={classNames(
                      styles.tab,
                      selectedTab === "events" && styles.selectedTab
                    )}
                    onClick={() => setSelectedTab("events")}
                  >
                    Events
                  </div>
                  <div
                    className={classNames(
                      styles.tab,
                      selectedTab === "parking" && styles.selectedTab
                    )}
                    onClick={() => setSelectedTab("parking")}
                  >
                    Parking
                  </div>
                </div> */}
                {selectedTab === "events" && (
                  <>
                    {performer?.home_venue_id && (
                      <div className={styles.nearbyEventContainer}>
                        <div className={styles.nearbyEvents}>
                          <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            preload="auto"
                            className={styles.nearbyVideo}
                          >
                            <source
                              src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/ticket_video_artist.mp4"
                              type="video/mp4"
                            />
                          </video>
                          <h2>Upcoming Home Games</h2>
                          <div
                            className={styles.moreButton}
                            onClick={() => setShowAllHomeGames(true)}
                          >
                            More
                          </div>
                        </div>
                        {nonFilteredHomeGames?.map((event, i) => {
                          if (!showAllHomeGames && i > 2) return null
                          return (
                            <EventListItem
                              event={event}
                              home
                              performer={performer}
                            />
                          )
                        })}
                      </div>
                    )}
                    <h2>All {performer?.name} Events</h2>
                    <FilterRow />
                    {selectedSportsFilter === "All" && (
                      <>
                        {allGames?.length > 0 &&
                          allGames.map((event, i) => {
                            if (numberOfResults && i >= numberOfResults)
                              return null
                            return (
                              <EventListItem
                                event={event}
                                home
                                performer={performer}
                              />
                            )
                          })}
                        {numberOfResults &&
                          allGames?.length > numberOfResults && (
                            <Button
                              compact
                              leftIcon={<ArrowDown />}
                              className={styles.showMoreButton}
                              onClick={() => setNumberOfResults(null)}
                              variant="beigeOutline"
                            >
                              Show More
                            </Button>
                          )}
                        {allGames.length === 0 && (
                          <div className={styles.noResults}>
                            <RedX />
                            <h1>No Events Found</h1>
                            <p>Try another search or adjust your filters.</p>
                            <Button
                              onClick={() => navigate("/events")}
                              variant="beigeOutline"
                              className={styles.explore}
                            >
                              Back
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                    {selectedSportsFilter === "Home" && (
                      <>
                        {homeGames.length > 0 &&
                          homeGames?.map((event, i) => {
                            if (numHomeResults && i >= numHomeResults)
                              return null
                            return (
                              <EventListItem
                                event={event}
                                home
                                performer={performer}
                              />
                            )
                          })}
                        {homeGames.length === 0 && (
                          <div className={styles.noResults}>
                            <RedX />
                            <h1>No Events Found</h1>
                            <p>Try another search or adjust your filters.</p>
                            <Button
                              onClick={() => navigate("/events")}
                              variant="beigeOutline"
                              className={styles.explore}
                            >
                              Back
                            </Button>
                          </div>
                        )}

                        {numHomeResults &&
                          homeGames?.length > numHomeResults && (
                            <Button
                              compact
                              leftIcon={<ArrowDown />}
                              className={styles.showMoreButton}
                              onClick={() => setNumHomeResults(null)}
                              variant="beigeOutline"
                            >
                              Show More
                            </Button>
                          )}
                      </>
                    )}
                    {selectedSportsFilter === "Away" && (
                      <>
                        {awayGames?.length > 0 &&
                          awayGames?.map((event, i) => {
                            if (numAwayResults && i >= numAwayResults)
                              return null
                            return (
                              <EventListItem
                                event={event}
                                away
                                performer={performer}
                              />
                            )
                          })}

                        {awayGames.length === 0 && (
                          <div className={styles.noResults}>
                            <RedX />
                            <h1>No Events Found</h1>
                            <p>Try another search or adjust your filters.</p>
                            <Button
                              onClick={() => navigate("/events")}
                              variant="beigeOutline"
                              className={styles.explore}
                            >
                              Back
                            </Button>
                          </div>
                        )}

                        {numAwayResults &&
                          awayGames?.length > numAwayResults && (
                            <Button
                              compact
                              rightIcon={<ArrowDown />}
                              className={styles.showMoreButton}
                              onClick={() => setNumAwayResults(null)}
                              variant="beigeOutline"
                            >
                              Show More
                            </Button>
                          )}
                      </>
                    )}
                  </>
                )}
                {selectedTab === "parking" && (
                  <>
                    {parking?.length > 0 && (
                      <>
                        <h2>Parking</h2>
                        {parking?.map((event, i) => (
                          <EventListItem event={event} performer={performer} />
                        ))}
                      </>
                    )}
                    {parking?.length === 0 && (
                      <div className={styles.noResults}>
                        <RedX />
                        <h1>No Parking Found</h1>
                        <p>Try another search or adjust your filters.</p>
                        <Button
                          onClick={() => navigate("/events")}
                          variant="beigeOutline"
                          className={styles.explore}
                        >
                          Back
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {!isMidfunnel && (
          <div className={styles.recommendationContainer}>
            <h2>You May Also Like: </h2>
            <div className={styles.recommendations}>
              {filteredRecommendations.map((event, i) => {
                if (i > 3) return null
                return <CompactEventCard event={event} key={event.event_id} />
              })}
            </div>
          </div>
        )}

        {isMidfunnel && window.innerWidth <= 960 && <MidfunnelCopy />}

        {isMidfunnel && (
          <div className={styles.midfunnelContainer}>
            <div className={styles.eventRecommendations}>
              <div className={styles.carouselLoadingPlaceholder}>
                <EventCarousel
                  title={"You May Also Like:"}
                  events={filteredRecommendations}
                  link={`/events?event_type=${events[0]?.event_type}&nearby=true`}
                  showLocationPicker={false}
                  showCTA={false}
                />
              </div>
            </div>
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              className={styles.heroVideo}
              width={window.innerWidth}
              id="bg-shield-artist"
            >
              <source
                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Shield_v05.mp4"
                type="video/mp4"
              />
            </video>
            <picture>
              <img
                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Shield_v05.avif"
                alt="bg-shield-artist"
                id="bg-shield-fallback"
                className={classNames(styles.heroVideo, styles.fallbackImage)}
              />
            </picture>

            <QualityXperienceGuarantee className={styles.xperience} />
            <div className={styles.emailCaptureOuter}>
              <div className={styles.emailCaptureSection}>
                <div className={styles.emailCaptureLeft}>
                  <h2>Stay in the know</h2>
                  <p>Sign up to discover the best events happening near you.</p>
                </div>
                <div className={styles.emailCaptureRight}>
                  <InlineInput
                    disabled={submittedEmail}
                    placeholder="Enter your email"
                    onSubmit={subscribe}
                    buttonText={submittedEmail ? "Submitted!" : "Submit"}
                  />

                  <p>
                    By signing up, you acknowledge and accept our privacy policy
                    and consent to receiving emails.
                  </p>
                </div>
              </div>
            </div>
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              className={classNames(styles.heroVideo, styles.bar)}
              id="bg-bar"
            >
              <source
                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Bar_N.mp4"
                type="video/mp4"
              />
            </video>
            <picture>
              <source
                srcset="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Bar_N_Flipped.avif"
                type="image/avif"
              />
              <img
                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Bar_N_Flipped.png"
                id="bg-bar-fallback"
                alt="bar"
                className={classNames(
                  styles.heroVideo,
                  styles.bar,
                  styles.fallbackImage
                )}
              />
            </picture>
          </div>
        )}
      </Layout>
    </>
  )
}

export default ArtistPage
