import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import classNames from "classnames"
import { Blurhash } from "react-blurhash"
import { Button, CustomDatePicker, Layout } from "../components"
import styles from "./VenuePage.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { setSelectedEvent } from "../reducers/eventSlice"
import { AngleDown, ArrowDown, Calendar, RedX, XCircle } from "../css/icons"
import { recordIterablePageview } from "../helpers/recordIterableUser"

const ConditionalLink = ({ children, to, condition, event }) => {
  const dispatch = useDispatch()

  return condition ? (
    <Link
      className={styles.eventListItem}
      to={to}
      onClick={() => dispatch(setSelectedEvent(event))}
    >
      {children}
    </Link>
  ) : (
    <a
      onClick={() => dispatch(setSelectedEvent(event))}
      className={styles.eventListItem}
      href={to}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

const EventListItem = ({ event }) => {
  const dispatch = useDispatch()
  return (
    <ConditionalLink
      className={styles.eventListItem}
      to={
        event?.min_ticket_price
          ? `/event/${event.event_id}`
          : "https://discord.gg/GYrHjHjybg"
      }
      condition={event?.min_ticket_price}
      onClick={() => dispatch(setSelectedEvent(event))}
    >
      <div className={styles.dateTag}>
        <div className={styles.month}>{event?.date_month}</div>
        <div className={styles.day}>{event?.date_day}</div>
        <div className={styles.dateTime}>
          {event?.date_formatted.split(",")[0]}{" "}
          {event?.date_formatted.split(" ")[4]}{" "}
          {event?.date_formatted.split(" ")[5]}
        </div>
      </div>
      <div className={styles.eventInfo}>
        <h3>{event.short_title || event.title}</h3>
        <div className={styles.priceContainer}>
          {event?.min_ticket_price ? (
            <div className={styles.priceDescription}>
              <span className={styles.price}>
                From{" "}
                <span className={styles.amt}>
                  ${Math.ceil(event.min_ticket_price / 100)}
                </span>
              </span>
            </div>
          ) : (
            <div className={styles.priceDescription}>
              <span className={classNames(styles.price, styles.discord)}>
                Request in Discord
              </span>
            </div>
          )}
        </div>
      </div>
    </ConditionalLink>
  )
}

const VenuePage = () => {
  const params = useParams()
  const navigate = useNavigate()

  const [venue, setVenue] = useState(null)
  const [events, setEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [numberOfResults, setNumberOfResults] = useState(8)
  const [eventsLoaded, setEventsLoaded] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [showDateMenu, setShowDateMenu] = useState(false)
  const user = useSelector((state) => state.user?.user)

  const venue_id = params.venue_id

  useEffect(() => {
    getVenue()
    getAllEvents()

    if (user) {
      recordIterablePageview(user, window.location.pathname, venue?.name)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getVenue()
    getAllEvents()
  }, [params.venue_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterEvents()
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterEvents(startDate, endDate)
  }, [startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const getVenue = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/venue-by-id`
    let params = { venue_id }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setVenue(resp.venue)
  }

  const getAllEvents = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = { venue_id: venue_id }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setEvents(resp.events)
    setEventsLoaded(true)
  }

  const filterEvents = (startDate, endDate) => {
    let otherEvents = []

    const filteredByDate = []

    if (startDate && endDate) {
      filteredByDate.push(
        ...events.filter((event) => {
          return (
            new Date(event.date_local) >= new Date(startDate) &&
            new Date(event.date_local) <= new Date(endDate) &&
            event.event_type === events[0]?.event_type
          )
        })
      )
    } else {
      filteredByDate.push(...events)
    }

    filteredByDate?.forEach((event) => {
      if (event.min_ticket_price === null) return
      otherEvents.push(event)
    })

    setFilteredEvents(otherEvents)
    setTimeout(() => {}, 1000)
  }

  const metaTitle = `${venue?.name} | XP — Access your favorite shows`
  const metaDescription = `Get tickets to ${venue?.name} and to your other favorite concerts, sports games, & more, with no hidden fees.`
  const shareFallbackImage =
    "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Social+Share+Image.png"

  const showEndDate =
    startDate &&
    endDate &&
    startDate?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }) !==
      endDate?.toLocaleDateString("en-us", {
        month: "short",
        day: "numeric",
      })

  return (
    <>
      <Layout>
        <Helmet>
          <title>{metaTitle}</title>
          <meta property="og:title" content={metaTitle} />
          <meta property="twitter:title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta property="og:description" content={metaDescription} />
          <meta property="twitter:description" content={metaDescription} />
          <meta
            property="og:image"
            content={venue?.image || shareFallbackImage}
          />
          <meta
            property="twitter:image"
            content={venue?.image || shareFallbackImage}
          />
        </Helmet>
        <div className={styles.blurhashContainer}>
          {venue?.image_blurhash && (
            <Blurhash
              hash={venue?.image_blurhash}
              width={window.innerWidth}
              height={window.innerHeight}
              resolutionX={32}
              resolutionY={32}
              punch={0}
            />
          )}
          <div className={styles.blurhashOverlay} />
        </div>
        <div className={styles.artistPageContainer}>
          <div className={styles.artistLeftColumn}>
            <div className={styles.venueImageContainer}>
              <img
                src={
                  venue?.image ||
                  "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
                }
                alt={venue?.name}
                className={styles.venueImage}
              />
            </div>
            <h1>{venue?.name}</h1>
          </div>

          <div className={styles.artistEventList}>
            <div className={styles.filterContainer}>
              <div>
                <Button
                  leftIcon={<Calendar className={styles.calendar} />}
                  compact
                  variant="gray"
                  rightIcon={
                    startDate ? (
                      <XCircle className={styles.close} />
                    ) : (
                      <AngleDown className={styles.angleDown} />
                    )
                  }
                  rightIconOnClick={(e) => {
                    e.stopPropagation()
                    if (startDate) {
                      setShowDateMenu(false)
                      setStartDate(null)
                      setEndDate(null)
                    }
                  }}
                  className={classNames(
                    styles.filterButton,
                    !!startDate ? styles.selected : styles.unselected
                  )}
                  onClick={() => {
                    setShowDateMenu(!showDateMenu)
                  }}
                >
                  {!startDate && "Date"}
                  {startDate &&
                    startDate.toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                    })}
                  {showEndDate &&
                    ` - ${endDate?.toLocaleDateString("en-us", {
                      month: "short",
                      day: "numeric",
                    })}`}
                </Button>
                {showDateMenu && (
                  <CustomDatePicker
                    className={styles.datePicker}
                    containerStartDate={startDate}
                    setDates={(start, end) => {
                      setStartDate(start)
                      setEndDate(end)
                    }}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    containerEndDate={endDate}
                    onClose={() => setShowDateMenu(false)}
                  />
                )}
              </div>
            </div>

            {events[0] && (
              <>
                <h2>Events</h2>
                <div>
                  {filteredEvents?.map((event, i) => {
                    if (numberOfResults && i >= numberOfResults) return null
                    return <EventListItem event={event} />
                  })}
                </div>

                {numberOfResults &&
                  filteredEvents?.length > numberOfResults && (
                    <Button
                      compact
                      rightIcon={<ArrowDown />}
                      className={styles.showMoreButton}
                      onClick={() => setNumberOfResults(null)}
                      variant="outline"
                    >
                      Show More
                    </Button>
                  )}
              </>
            )}
            {eventsLoaded && filteredEvents?.length === 0 && (
              <div className={styles.noResults}>
                <RedX />
                <h1>No Events Found</h1>
                <p>Try another search or adjust your filters.</p>
                <Button
                  onClick={() => navigate("/events")}
                  variant="beigeOutline"
                  className={styles.explore}
                >
                  Back
                </Button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default VenuePage
